import * as React from 'react';
import Logo from '../assets/svg/logo.svg';
import FacebookIcon from '../assets/svg/facebook-icon.svg';
import InstagramIcon from '../assets/svg/instagram-icon.svg';
import TelegramIcon from '../assets/svg/telegram-icon.svg';
import {Link} from 'gatsby';
import Kitty from '../assets/svg/kitty.svg';

export const Footer = ({language}) => (
  <footer className="footer">
    <div className="content footer__content">
      <div className="footer__logo-and-socials">
        <div className={'logo footer__logo'}>
          <Kitty className={'logo__kitty'} />
          <div className="logo__text">{content[language].logoText}</div>
        </div>
        <div className="footer__socials">
          <a
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/kitty_soloma"
          >
            Telegram
          </a>
          <a
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/kittysoloma"
          >
            Facebook
          </a>
          <a
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/kitty_soloma"
          >
            Instagram
          </a>
        </div>
        <div className="footer__socials-mobile">
          <a target="_blank" rel="noreferrer" href="https://t.me/kitty_soloma">
            <TelegramIcon className="footer__socials-icon" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/kittysoloma">
            <FacebookIcon className="footer__socials-icon" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/kitty_soloma">
            <InstagramIcon className="footer__socials-icon" />
          </a>
        </div>
      </div>
      <div className="footer__links">
        <div className="footer__links-column">
          <Link className="footer__link" to="#who-we-are">
            {content[language].whoWeAre}
          </Link>
          <Link className="footer__link" to="#how-to-help">
            {content[language].howToHelp}
          </Link>
          <a
              className="footer__link"
              href="/publichna-oferta"
          >
            {content[language].terms}
          </a>
        </div>
        <div className="footer__links-column">
          <a
            className="footer__link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/kitty_pechersk"
          >
            {content[language].pechersk}
          </a>
          <a
            className="footer__link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/kitty_lviv"
          >
            {content[language].lviv}
          </a>
          <a
            className="footer__link"
            target="_blank"
            rel="noreferrer"
            href="https://t.me/kitty_chernivtsi"
          >
            {content[language].chernivtsi}
          </a>
        </div>
      </div>
    </div>
  </footer>
);

const content = {
  en: {
    whoWeAre: 'About us',
    howToHelp: 'How to help',
    terms: 'Terms of use',
    pechersk: 'Pechersk Cats',
    chernivtsi: 'Chernivtsi Cats',
    lviv: 'Lviv Cats',
    logoText: 'soloma \ncats',
  },
  ua: {
    whoWeAre: 'Про нас',
    howToHelp: 'Як нам допомогти',
    terms: 'Публічна оферта',
    pechersk: 'Печерські котики',
    chernivtsi: 'Чернівецькі котики',
    lviv: 'Львівські котики',
    logoText: 'соломʼянські \nкотики',
  },
};
