import * as React from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import SEO from './SEO';

import '../styles/Main.scss';

export const Layout = ({children, seo, language}) => (
  <>
    <SEO {...seo} />
    <Header language={language} />
    <main className="main">{children}</main>
    <Footer language={language} />
  </>
);
