import * as React from 'react';
import {Helmet} from 'react-helmet';

const SEO = ({title, description}) => {
  // const image = ogImage.url
  //   ? ogImage
  //   : {
  //       url: '',
  //       dimensions: {height: 600, width: 1200},
  //     };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content="https://kittysoloma.org/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/*<meta property="og:image" content="" />*/}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="kittysoloma.org" />
      <meta property="twitter:url" content="https://kittysoloma.org/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/*<meta name="twitter:image" content="" />*/}
    </Helmet>
  );
};

export default SEO;
